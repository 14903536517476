import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Who Are Our Clients?</h2>
        </div>
        <div className="row testimonials-row">
          <div className="col">
            <h1>Independent Mortgage Brokers:</h1>
            <p>We streamline the pre-qualification process through automated data collection and AI-driven validation, ensuring compliance with conventional loan guidelines.</p>
            <p>Our platform enables mortgage brokers to efficiently screen online mortgage applications by analyzing borrowers’ credit reports, asset statements, W-2s, and other key documents. We deliver analytical insights and suggest eligibility outcomes.</p>
          </div>
          {/* <div className="col">
            <h1>Individual Landlords & Property Managers:</h1>
            <p>We assist landlords and property managers in tenant pre-screening, helping them assess a tenant's ability, willingness, and sustainability to pay rent.</p>
            <p>Our solution provides customized insights and recommendations to support confident leasing decisions.</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
