import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contact Us</h2>
                <p>We're here to help! Our flexible and cost-effective solutions are designed to address your daily challenges.</p>
                  <p>Reach out to us at <a className="underline" href="mailto:CustomerService@FinQuantumInc.com">CustomerService@FinQuantumInc.com</a> with any questions or inquiries.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2025 FinQuantum Inc.
          </p>
        </div>
      </div>
    </div>
  );
};
